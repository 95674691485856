// import "whatwg-fetch";
import React from "react";

export const BASE_URL =
  process.env.NODE_ENV === "development" ? "/s/api" : "/s/api";

export const BASE_HOST =
  process.env.NODE_ENV === "development"
    ? "https://app.tuteria.com"
    : "https://www.tuteria.com";
export const RequestContext = React.createContext({
  state: {},
  updateState: () => {},
  saveInitialData: () => {},
  initializeGroupLessons: () => {},
  saveLessonInfo: () => {},
  saveUpsellInfo: () => {},
  getVicinities: () => {},
  fetchRequestDetailFromSlug: () => {},
  checkCoupon: () => {},
  setDefaultState: () => {},
  getStudents: () => {},
  updateGroupLessonPayment: () => {},
  getGroupLessonInfo: () => {}
});
class UpdateDefaultRequestDetails extends React.Component {
  componentDidMount() {
    let { setDefaultState, kind = "hometutoring" } = this.props;
    setDefaultState(
      { request_details: this.defaultParentRequestData(kind) },
      kind
    );
  }
  defaultParentRequestData(kind) {
    if (kind === "exams") {
      return {};
    }
    return {
      processing_fee: 0,
      hours: 0,
      days: [],
      no_of_teachers: 1,
      transport_fare: 0,
      classes: [],
      per_hour: 0,
      discount: 0
    };
  }
  render() {
    return this.props.children;
  }
}
export class DefaultRequestInitializer extends React.Component {
  render() {
    let { kind } = this.props;
    return (
      <RequestContext.Consumer>
        {({ setDefaultState }) => {
          return (
            <UpdateDefaultRequestDetails {...{ setDefaultState, kind }}>
              {this.props.children}
            </UpdateDefaultRequestDetails>
          );
        }}
      </RequestContext.Consumer>
    );
  }
}
export class RequestProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kind: null,
      context: {
        state: {
          currentStep: this.props.currentStep || 1,
          allSteps: this.props.allSteps || 1,
          request_details: this.props.request_details,
          agent: {},
          hourFactor: [{ hour: "1", factor: 50 }, { hour: "1.5", factor: 25 }],
          marketing_channels: [],
          planFactor: {},
          oneWeekFactor: 1.4,
          groupLessons: [],
          payment_details: {},
          selectedDetails: this.props.selectedDetails || {},
          discount_info: undefined
        },
        updateState: this.updateState,
        saveInitialData: this.saveInitialData,
        saveLessonInfo: this.saveLessonInfo,
        saveUpsellInfo: this.saveUpsellInfo,
        getVicinities: this.getVicinities,
        fetchRequestDetailFromSlug: this.fetchRequestDetailFromSlug,
        checkCoupon: this.checkCoupon,
        setDefaultState: this.setDefaultState,
        getStudents: this.getStudents,
        initializeGroupLessons: this.initializeGroupLessons,
        getGroupLessonInfo: this.getGroupLessonInfo,
        updateGroupLessonPayment: this.updateGroupLessonPayment
      }
    };
  }
  defaultParentRequestData() {
    return {
      processing_fee: 0,
      hours: 0,
      days: [],
      no_of_teachers: 1,
      transport_fare: 0,
      classes: [],
      per_hour: 0,
      discount: 0
    };
  }
  componentWillMount() {
    let { state } = this.props;
    if (Boolean(state) && Object.keys(state).length > 0) {
      this.updateRequestFromServer(this.props.state);
    }
  }
  componentDidMount() {
    let { state } = this.props;
    if (Boolean(state) && Object.keys(state).length > 0) {
      this.updateRequestFromServer(this.props.state);
    }
  }
  updateState = (obj, update = true, callback = () => {}) => {
    let state = { ...this.state.context.state, ...obj };
    let context = { ...this.state.context, state };
    if (update) {
      this.setState({ context }, () => callback());
    }
    return context;
  };
  setDefaultState = (obj, kind = "hometutoring") => {
    let state = { ...this.state.context.state, ...obj };
    let context = { ...this.state.context, state };
    if (kind !== this.state.kind) {
      this.setState({ context, kind });
    }
  };
  fetchRequestDetailFromSlug = (slugValue, callback, force = false) => {
    let {
      context: {
        state: {
          slug,
          request_details,
          hourFactor,
          planFactor,
          oneWeekFactor = 1.4,
          discount_info
        }
      }
    } = this.state;
    if (Boolean(slug) && !force) {
      callback({
        request_dump: { ...request_details, discount_info },
        hourFactor,
        planFactor,
        oneWeekFactor
      });
    } else {
      return this.getFreshLessonInfo(slugValue, callback);
    }
  };
  getGroupLessonInfo = slug => {
    let { request_details, payment_details } = this.state;
    if (request_details) {
      return new Promise((resolve, reject) =>
        resolve({ request_details, payment_details })
      );
    }
    return fetch(`${BASE_URL}/get-group-lesson/${slug}`)
      .then(jsonCallback)
      .then(data => {
        this.saveGroupLessonDetails(data);
        return data;
      });
  };
  updateGroupLessonPayment = (url, callback, errorCallback) => {
    return fetch(`${BASE_HOST}${url}`)
      .then(jsonCallback)
      .then(data => {
        callback(data);
      })
      .catch(err => {
        errorCallback(err);
      });
  };
  extendResult(value) {
    return {
      ...value
      // discount_info: {
      //   discount: 15,
      //   duration: 2,
      //   deadline: "June 13th",
      //   name: "COVID-19 Relief"
      // }
    };
  }
  getFreshLessonInfo = (slugValue, callback) => {
    return fetch(`${BASE_URL}/loc/lesson-schedule/${slugValue}`)
      .then(resp => resp.json())
      .then(data => {
        let request_dump = this.updateRequestFromServer(data.data);
        callback(request_dump);
      });
  };
  fetchFromServer(url, body) {
    return fetch(`${BASE_URL}${url}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }
  updateRequestFromServer(data = {}, callback = data => {}) {
    let {
      base_rate,
      hourFactor,
      request_dump,
      agent,
      planFactor = {},
      groupLessons = [],
      selectedDetails = {},
      payment_details = {},
      discount_info
    } = data;
    let { request_details = {} } = this.state.context.state;
    request_dump = {
      ...request_dump,
      agent,
      planFactor,
      hourFactor,
      groupLessons,
      selectedDetails,
      payment_details
    };
    if (base_rate) {
      request_dump.request_details.per_hour =
        base_rate || request_details.per_hour;
    }

    request_dump = this.extendResult(request_dump);
    if (discount_info) {
      request_dump.discount_info = discount_info;
    }
    let context = this.updateState(request_dump, false);
    // context.hourFactor = hourFactor;
    // context.planFactor = planFactor;
    this.setState({ context }, () =>
      callback({ request_dump, hourFactor, planFactor })
    );
    return { request_dump, hourFactor, planFactor };
  }
  saveGroupLessonDetails = data => {
    let rq = data;
    let context = this.updateState(rq, false);
    this.setState({ context }, () => {});
  };
  initializeGroupLessons = (
    lesson_info,
    personal_info,
    callback,
    errorCallback
  ) => {
    return this.fetchFromServer(`/create-group-lesson`, {
      lesson_info,
      personal_info
    })
      .then(jsonCallback)
      .then(data => {
        let rq = data.data;
        this.saveGroupLessonDetails(rq);

        callback(rq.slug);
      })
      .catch(error => errorCallback(error));
  };
  saveInitialData = (
    personal_info,
    location,
    allSteps,
    callback,
    errorCallback
  ) => {
    let { request_details = {} } = this.state.context.state;
    this.updateState({ allSteps });
    return this.fetchFromServer(`/save-initial-request`, {
      request_details,
      personal_info,
      location
    })
      .then(jsonCallback)
      .then(data => {
        this.updateRequestFromServer(data.data, ({ request_dump }) => {
          callback(request_dump.slug);
        });
      })
      .catch(error => {
        errorCallback(error);
      });
  };
  saveLessonInfo = (lessonInfo, callback) => {
    const {
      lessonCount,
      startTime,
      totalPrice,
      ...rest
    } = lessonInfo.request_details;
    return this.fetchFromServer(`/complete-request`, {
      slug: lessonInfo.slug,
      request_details: { ...rest }
    })
      .then(resp => resp.json())
      .then(data => {
        this.updateRequestFromServer(data.data, ({ agent }) => {
          callback(agent);
        });
      });
  };
  saveUpsellInfo = upsellInfo => {};
  getVicinities = state => {
    return fetch(`${BASE_URL}/get-vicinities?state=${state}`)
      .then(resp => resp.json())
      .catch(err => console.log(err));
  };
  getStudents = slug => {
    return fetch(`${BASE_URL}/get-students/${slug}`)
      .then(resp => resp.json())
      .catch(err => console.log(err));
  };
  checkCoupon = (coupon, callback) => {
    return fetch(`${BASE_URL}/coupons?coupon=${coupon}`)
      .then(resp => resp.json())
      .then(({ data }) => {
        if (Boolean(data.expiry_date) && daysRemaining(data.expiry_date) > 0) {
          callback(parseInt(data.discount, 10));
        } else {
          callback(0);
        }
      });
  };
  render() {
    return (
      <RequestContext.Provider value={this.state.context}>
        {this.props.children}
      </RequestContext.Provider>
    );
  }
}

function daysRemaining(value) {
  return (new Date(value) - new Date()) / (1000 * 60 * 60 * 24);
}

function jsonCallback(resp) {
  if (resp.status >= 400) {
    // eslint-disable-next-line
    throw "Error saving data";
  } else {
    return resp.json();
  }
}
