import React from "react";
import { ThemeProvider } from "@chakra-ui/core";

import { theme as ChakraTheme } from "@chakra-ui/core";
// import icons from "./components/ui-elements/icons";
const theme = {
  ...ChakraTheme,
  borders: {
    ...ChakraTheme.borders,
    none: "none",
    normal: "1px solid"
  },
  sizes: {
    ...ChakraTheme.sizes
  },
  borderWidths: {
    xl: "2rem",
    lg: "1rem",
    md: "0.5rem",
    sm: "0.25rem",
    xs: "0.125rem",
    "2xs": "0.0625rem",
    "3xs": "0.03125rem",
    none: 0
  },
  icons: {
    ...ChakraTheme.icons
    // ...icons
  },
  fonts: {
    ...ChakraTheme.fonts,
    heading: "Larsseit, Helvetica Neue, Open Sans, sans-serif",
    body: "Inter, Helvetica Neue, Open Sans, sans-serif"
  },
  typography: {
    fontFamily: {
      heading: "Larsseit, Helvetica Neue, Open Sans, sans-serif",
      text: "Inter, Helvetica Neue, Open Sans, sans-serif"
    },
    fontSizes: {
      heading: {
        bigger: {
          desktop: 56,
          mobile: 48
        },
        big: {
          desktop: 48,
          mobile: 40
        },
        regular: {
          desktop: 40,
          mobile: 32
        },
        small: {
          desktop: 32,
          mobile: 28
        },
        smaller: 28,
        smallest: 24
      },
      text: {
        biggest: {
          desktop: 28,
          mobile: 24
        },
        bigger: {
          desktop: 24,
          mobile: 20
        },
        big: 18,
        regular: 16,
        small: 14,
        smaller: 12,
        smallest: 10
      }
    },
    fontWeights: {
      heading: {
        regular: 500,
        medium: 700,
        bold: 800
      },
      text: {
        regular: 400,
        medium: 600,
        bold: 700
      }
    },
    letterSpacing: {
      compact: "-0.8px",
      regular: 0,
      wide: "0.8px"
    },
    lineHeights: {
      heading: {
        none: "normal",
        short: 1.0,
        regular: 1.2,
        tall: 1.3
      },
      text: {
        shortest: 1.0,
        shorter: 1.2,
        short: 1.3,
        regular: 1.5,
        tall: 1.6,
        none: "normal"
      }
    }
  },
  radii: {
    full: "9999px",
    lg: "0.5rem",
    md: "0.25rem",
    none: "0",
    sm: "0.125rem",
    noLeftRadius: `0 4px 4px 0`,
    noRightRadius: `4px 0 0 4px`
  },
  colors: {
    ...ChakraTheme.colors,
    blue: {
      50: "#E7EFFC",
      100: "#B9D0F7",
      200: "#8BB1F3",
      300: "#5C92EE",
      400: "#2E73E9",
      500: "#0054E5",
      600: "#0045BC",
      700: "#003692",
      800: "#002769",
      900: "#00173F"
    },
    teal: {
      50: "#E7F3F7",
      100: "#B9DB37",
      200: "#8BC3D7",
      300: "#5CABC7",
      400: "#2E93B7",
      500: "#007BA7",
      600: "#006589",
      700: "#004F6B",
      800: "#00384C",
      900: "#00222E"
    },
    coral: {
      50: "#FFF3EF",
      100: "#FFDCCF",
      200: "#FFC4AF",
      300: "#FFAD8F",
      400: "#FF966F",
      500: "#FF7F50",
      600: "#D16842",
      700: "#A35133",
      800: "#743A25",
      900: "#462316"
    },
    brown: {
      50: "#F6EBEB",
      100: "#E6C4C4",
      200: "#D69E9E",
      300: "#C57777",
      400: "#5BB050",
      500: "#A52A2A",
      600: "#882323",
      700: "#6A1B1B",
      800: "#4B1414",
      900: "#2D0C0C"
    },
    green: {
      50: "#EDF8F4",
      100: "#CAEADE",
      200: "#A6DDC8",
      300: "#83CFB2",
      400: "#60C29C",
      500: "#3DB586",
      600: "#32956E",
      700: "#277456",
      800: "#1C533D",
      900: "#113225"
    },
    red: {
      50: "#FCEFEC",
      100: "#F6D0C8",
      200: "#F1B2A3",
      300: "#EB937F",
      400: "#E6745A",
      500: "#E15636",
      600: "#B9472D",
      700: "#903723",
      800: "#672819",
      900: "#3E180F"
    },
    crimson: {
      50: "#FDE9ED",
      100: "#FABECA",
      200: "#F694A6",
      300: "#F36983",
      400: "#F03E60",
      500: "#ED143D",
      600: "#C21132",
      700: "#970D27",
      800: "#6C0A1C",
      900: "#410611"
    },
    purple: {
      50: "#F2E8FF",
      100: "#D9BCFF",
      200: "#BF8FFF",
      300: "#A663FF",
      400: "#8D36FF",
      500: "#740AFF",
      600: "#5F09D1",
      700: "#4A07A3",
      800: "#350574",
      900: "#200346"
    },
    magenta: {
      50: "#F3EBF2",
      100: "#DCC3D8",
      200: "#C69BBE",
      300: "#AF73A4",
      400: "#984B8A",
      500: "#822470",
      600: "#6B1E5C",
      700: "#531748",
      800: "#3C1133",
      900: "#240A1F"
    },
    orange: {
      50: "#FCF0E8",
      100: "#F7D3BB",
      200: "#F3B78F",
      300: "#EE9A62",
      400: "#E97D35",
      500: "#E56109",
      600: "#BC5008",
      700: "#923E06",
      800: "#692D05",
      900: "#3F1B03"
    },
    yellow: {
      50: "#FEFCE7",
      100: "#FDF6B9",
      200: "#FCF18B",
      300: "#FBEB5C",
      400: "#FAE62E",
      500: "#FAE100",
      600: "#CDB900",
      700: "#A09000",
      800: "#726700",
      900: "#453E00"
    },
    gold: {
      50: "#FFF8E7",
      100: "#FFEBB9",
      200: "#FFDD8B",
      300: "#FFD05C",
      400: "#FFC32E",
      500: "#FFB600",
      600: "#D19500",
      700: "#A37400",
      800: "#745300",
      900: "#463200"
    },
    gray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E3E7ED",
      300: "#BABEC2",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923"
    },
    gray2: {
      50: "#EBECED",
      100: "#EDEFF3",
      200: "#E3E7ED",
      300: "#747A83",
      400: "#4C5460",
      500: "#252E3D",
      600: "#1F2632",
      700: "#181E27",
      800: "#11151C",
      900: "#0B0D11"
    },
    neutral: {
      50: "#FCFCFD",
      100: "#F7F8FA",
      200: "#F2F4F6",
      300: "#EDEFF3",
      400: "#E8EBF0",
      500: "#E3E7ED",
      600: "#BABEC2",
      700: "#919397",
      800: "#68696C",
      900: "#3E3F41"
    }
  },
  size: {
    icon: {
      small: 12,
      regular: 16,
      big: 24,
      bigger: 32
    },
    input: {
      big: {
        height: 64,
        fontSize: 16,
        iconPadding: 36
        // minHeight: 101
      },
      regular: {
        height: 48,
        fontSize: 16,
        iconPadding: 36
        // minHeight: 101
      },
      small: {
        height: 40,
        fontSize: 14,
        iconPadding: 36
        // minHeight: 83
      }
    },
    avatar: {
      biggest: { avatarSize: 128, fontSize: 32 },
      bigger: { avatarSize: 96, fontSize: 24 },
      big: { avatarSize: 64, fontSize: 18 },
      regular: { avatarSize: 48, fontSize: 16 },
      small: { avatarSize: 32, fontSize: 12 },
      smaller: { avatarSize: 24, fontSize: 12 }
    },
    modal: {
      small: 320,
      regular: 480,
      medium: 560,
      big: 800,
      bigger: 960
    },
    tag: {
      small: {
        height: 28,
        maxWidth: 156,
        padding: "0 8px",
        fontSize: 12
      },
      regular: {
        height: 32,
        maxWidth: 180,
        padding: "0 12px",
        fontSize: 14
      }
    }
  },
  shadow: {
    none: "none",
    level1: "0 0.5px 2px rgba(0, 0, 0, 0.18)",
    level2: "0 1px 4px rgba(0, 0, 0, 0.16)",
    level3: "0 2px 8px rgba(0, 0, 0, 0.16)",
    level4: "0 4px 16px rgba(0, 0, 0, 0.16)"
  },
  borderRadius: {
    none: 0,
    small: 2,
    regular: 4,
    big: 8,
    bigger: 16,
    biggest: 24,
    round: 9999
  },
  appearance: {
    modal: {
      overlay: "rgba(0,0,0,0.65)"
    },
    status: {
      error: {
        icon: "error",
        color: "#E15636",
        lightColor: "#FFEBE6"
      },
      success: {
        icon: "check",
        color: "#3DB586",
        lightColor: "#DEF7EA"
      },
      warning: {
        icon: "warning",
        color: "#E8A600",
        lightColor: "#FFFAE6"
      },
      info: {
        icon: "info",
        color: "#0054E5",
        lightColor: "#DDEAFF"
      }
    },
    tag: {
      outline: {
        base: {
          borderColor: "#E3E7ED",
          background: "none",
          color: "#252E3D",
          ":hover": {
            borderColor: "#F4F5F7",
            backgroundColor: "#EDF0F4"
          }
        },
        ":active": {
          borderColor: "#D7DDE5",
          backgroundColor: "#D7DDE5"
        }
      },
      "outline-green": {
        base: {
          borderColor: "#E3E7ED",
          background: "none",
          color: "#3DB586",
          ":hover": {
            borderColor: "#E3E7ED",
            backgroundColor: "none"
          }
        },
        ":active": {
          borderColor: "#E3E7ED",
          backgroundColor: "none"
        }
      },
      "outline-blue": {
        base: {
          borderColor: "#E3E7ED",
          background: "none",
          color: "#0054E5",
          ":hover": {
            borderColor: "#E3E7ED",
            backgroundColor: "none"
          }
        },
        ":active": {
          borderColor: "#E3E7ED",
          backgroundColor: "none"
        }
      },
      blue: {
        base: {
          borderColor: "hsla(217, 100%, 90%, 1)",
          background: "hsla(217, 100%, 90%, 1)",
          color: "#435066",
          ":hover": {
            borderColor: "#AECCF9",
            background: "#AECCF9"
          }
        },
        ":active": {
          borderColor: "#0054E5",
          backgroundColor: "#0054E5",
          color: "#ffffff"
        }
      },
      green: {
        base: {
          borderColor: "hsla(217, 100%, 90%, 1)",
          background: "#3DB586",
          color: "#fff",
          ":hover": {
            background: "#37A378"
          }
        },
        ":active": {
          backgroundColor: "#3DB586",
          color: "#fff"
        }
      }
    }
  }
};

// Use at the root of your app

const EnhanceProvider = props => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default EnhanceProvider;
