import React from "react";
import Loadable from "react-loadable";
import { MyLoadingComponent } from "tuteria-frontend-components/src/pages/LoadingComponent";

import { Route } from "react-router-dom";

// let DEVELOPMENT = process.env.NODE_ENV === "development";
export function generateLoadableComponent(params) {
  return Loadable({
    loading: MyLoadingComponent,
    ...params
  });
}

// function isServer() {
//   try {
//     if (window) {
//       return false;
//     }
//   } catch (e) {
//     if (e instanceof ReferenceError) {
//       return true;
//     }
//   }
// }

// class AnalyticsContainer extends React.Component {
//   state = {
//     currentRoute: ""
//   };
//   componentDidMount() {
//     console.log(window.location.href);
//     this.updateRoute(this.props);
//   }
//   componentWillReceiveProps(nextProps) {
//     if (this.props.location !== nextProps.location) {
//       this.updateRoute(nextProps);
//     }
//     // console.log({ current: this.props.location });
//     // console.log({ next: nextProps.location });
//   }
//   updateRoute = props => {
//     if ((window || {}).ga) {
//       let ga = window.ga;
//       ga("set", "page", props.location);
//       ga("send", "pageview");
//       console.log(props.location);
//       ga(function(tracker) {
//         // Logs the tracker created above to the console.
//         console.log(tracker);
//       });
//     }
//     this.setState({ currentRoute: props.location });
//   };
//   render() {
//     return this.props.children;
//   }
// }

export const AnalyticsRoute = ({ render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        // if (!DEVELOPMENT && !isServer()) {
        //   if (window) {
        //     let { protocol, origin } = window.location;
        //     if (
        //       !protocol.includes("https") ||
        //       !origin.includes("tuteria.com") ||
        //       !origin.includes("localhost")
        //     ) {
        //       window.location.href = "https://www.tuteria.com";
        //     }
        //   }
        // }

        return (
          // <AnalyticsContainer location={props.location.pathname}>
          render(props)
          // {render(props)}
          // </AnalyticsContainer>
        );
      }}
    />
  );
};
