import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { App as Routers } from "./routes";
import Loadable from "react-loadable";
import "tuteria-frontend-components/src/fonts/index";
import data from "tuteria-frontend-components/src/pages/ExternalPages/GroupLandingPage/data.json";
import {
  processData,
  formatReactStaticData
} from "tuteria-frontend-components/src/pages/ExternalPages/GroupLandingPage/utils";
let FETCHED_DATA = processData(data);
// if (process.env.NODE_ENV === "development") {
//   import(`tuteria-frontend-components/src/fonts/index`);
// }

let DOMReact = process.env.NODE_ENV === "development" ? "render" : "hydrate";
const App = ({ initialState }) => {
  return Routers({ initialState, Router });
};

if (process.env.NODE_ENV === "development") {
  window.initialState = {
    groupLessons: formatReactStaticData(FETCHED_DATA),
    slots: []
  };
}

Loadable.preloadReady().then(() => {
  ReactDOM[DOMReact](
    <App initialState={window.initialState} />,
    document.getElementById("root")
  );
});
