import React from "react";
import { Switch } from "react-router-dom";
import { generateLoadableComponent, AnalyticsRoute } from "./utils";
import {
  RequestContext,
  RequestProvider,
  DefaultRequestInitializer
} from "./RequestProvider";
import ScrollContainer from "tuteria-frontend-components/src/compound/ScrollContainer";
import EnhanceProvider from "./enhancer";
// import ParentLandingPage from "./LandingPage";

const ClientFlowPages = generateLoadableComponent({
  loader: () => import(`./ParentFlow`)
});
const ExamFlowPages = generateLoadableComponent({
  loader: () => import(`./ExamFlow`)
});
const GroupFlowPages = generateLoadableComponent({
  loader: () => import(`./GroupLessonFlow`)
});
const RebookLessonFlow = generateLoadableComponent({
  loader: () => import(`./RebookLessonFlow`)
});
const ParentLandingPage = generateLoadableComponent({
  loader: () => import(`./LandingPage`)
});
const ExamLandingPage = generateLoadableComponent({
  loader: () => import(`./ExamLandingPage`)
});
const GroupLessonPage = generateLoadableComponent({
  loader: () => import(`./GroupLessonPage`),
  delay: 3000
});
const parentRoutes = [
  "/s/hometutors",
  "/s/home-tutors-in-:state",
  "/home-tutors-in-:state"
];
const examRoutes = [
  "/s/:examname-tutors",
  "/s/:examname-tutors-in-:state",
  "/:examname-tutors-in-:state"
];
const groupRoutes = ["/s/groups"];
function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function(word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
}
class UpdateState extends React.Component {
  componentDidMount() {
    let { state, updateState } = this.props;
    if (Boolean(state)) {
      if (state[0] === "-") {
        state = state.slice(1);
      }
      updateState({ location: { state: titleCase(state) } });
    }
  }
  render() {
    return this.props.children;
  }
}
export const App = ({
  initialState,
  currentStep,
  Router,
  allSteps,
  routerProps = {}
}) => {
  return (
    <EnhanceProvider>
      <RequestProvider
        currentStep={currentStep}
        allSteps={allSteps}
        state={initialState}
      >
        <Router {...routerProps}>
          <ScrollContainer>
            <Switch>
              {parentRoutes.map(route => (
                <AnalyticsRoute
                  key={route}
                  path={route}
                  exact
                  strict
                  render={({ match: { params } }) => {
                    return (
                      <RequestContext.Consumer>
                        {props => (
                          <UpdateState
                            {...params}
                            updateState={props.updateState}
                          >
                            <ParentLandingPage {...params} />;
                          </UpdateState>
                        )}
                      </RequestContext.Consumer>
                    );
                  }}
                />
              ))}
              <AnalyticsRoute
                path="/s/hometutoring"
                render={params => (
                  <RequestContext.Consumer>
                    {props => (
                      <DefaultRequestInitializer kind="hometutoring">
                        <ClientFlowPages
                          {...params}
                          state={props}
                          basePath="/s/hometutoring"
                        />
                      </DefaultRequestInitializer>
                    )}
                  </RequestContext.Consumer>
                )}
              />
              {examRoutes.map(route => (
                <AnalyticsRoute
                  key={route}
                  path={route}
                  exact
                  strict
                  render={({ match: { params } }) => {
                    return (
                      <RequestContext.Consumer>
                        {props => (
                          <UpdateState
                            {...params}
                            updateState={props.updateState}
                          >
                            <ExamLandingPage {...params} />
                          </UpdateState>
                        )}
                      </RequestContext.Consumer>
                    );
                  }}
                />
              ))}
              <AnalyticsRoute
                path="/s/groups/:examname-lessons/:location/info"
                render={params => {
                  let pp = params.match.params;
                  return (
                    <RequestContext.Consumer>
                      {props => (
                        <DefaultRequestInitializer kind="exams">
                          <GroupFlowPages
                            {...params}
                            exam={pp.examname}
                            state={props}
                            basePath={`/s/groups/${pp.examname}-lessons/${
                              pp.location
                            }/info`}
                            baseRoute={ParentLandingPage}
                          />
                        </DefaultRequestInitializer>
                      )}
                    </RequestContext.Consumer>
                  );
                }}
              />
              {groupRoutes.map(route => (
                <AnalyticsRoute
                  key={route}
                  path={route}
                  // exact
                  strict
                  render={({ match: { params } }) => {
                    return (
                      <RequestContext.Consumer>
                        {props => (
                          <UpdateState
                            {...params}
                            updateState={props.updateState}
                          >
                            <GroupLessonPage
                              updateState={props.updateState}
                              groupLessons={props.state.groupLessons}
                              basePath={`/s/groups`}
                            />
                          </UpdateState>
                        )}
                      </RequestContext.Consumer>
                    );
                  }}
                />
              ))}

              <AnalyticsRoute
                path="/s/rebook-lessons/:slug"
                render={params => {
                  return <RebookLessonFlow {...params} />;
                }}
              />

              <AnalyticsRoute
                path="/s/:examname-tutors"
                render={params => {
                  return (
                    <RequestContext.Consumer>
                      {props => (
                        <DefaultRequestInitializer kind="exams">
                          <ExamFlowPages
                            {...params}
                            exam={params.match.params.examname}
                            state={props}
                            basePath={`/s/${
                              params.match.params.examname
                            }-tutors`}
                            baseRoute={ParentLandingPage}
                          />
                        </DefaultRequestInitializer>
                      )}
                    </RequestContext.Consumer>
                  );
                }}
              />
              <AnalyticsRoute
                path="/"
                render={({ match: { params } }) => {
                  return (
                    <RequestContext.Consumer>
                      {props => (
                        <UpdateState {...params} updteState={props.updateState}>
                          <ParentLandingPage {...params} />;
                        </UpdateState>
                      )}
                    </RequestContext.Consumer>
                  );
                }}
              />
            </Switch>
          </ScrollContainer>
        </Router>
      </RequestProvider>
    </EnhanceProvider>
  );
};
